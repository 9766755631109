<template>
    <div class="mainPage">
        <div class="mainPageContent">
            <div class="contentPanel">
                <div class="headerLogo">
                    <img src="../../assets/logo.png" alt="Osmose" />
                </div>
                <a :href="supportURL" target="_blank" class="osmoseUrl">
                    {{
                    supportURL
                    }}
                </a>
                <h2 class="title1"></h2>
                <button v-if="registering === false && wasRegistered===false" class="btnwht" @click="onLicenseProductClick()">
                    LICENSE PRODUCT
                </button>

                <div v-if="licenseExpired" class="textRed textCenter">
                    {{ licenseMsg }}
                </div>
                <button v-if="registering" class="btnwht" disabled="true">
                    Registering Stand-Alone Strength Calc. Please Wait...
                </button>
                <button v-if="wasRegistered" class="btnwht" @click="onRegisteredClick()">
                    {{ registerMsg }}
                </button>

            </div>
            <sc-loader v-show="isLoading"></sc-loader>
            <!-- <div class="bottomFixed" v-if="registering === false">
                <ul class="listInline">
                    <li class="listItem">
                        <a href="javascript: void(0);" @click="onSupportClick()">
                            SUPPORT
                        </a>
                    </li> -->
                    <!-- <li class="listItem">
          <a href="javascript: void(0);"> VIEW DEMO </a>
        </li> -->
<!--                 </ul>
            </div>
 -->    </div>
    </div>
    
</template>


<script>
    import SimpleIDB from "../../api/indexedDBService";
    import LicenseAPI from "@/api/LicenseAPI";
    import Loader from "../Loader.vue";
    import { store } from "../../store/index.js";
    import { v5 as uuidv5 } from "uuid";
    import { Device } from "@capacitor/device";
    import { finish } from 'fulcrum-extensions';

    export default {
        name: "LicenseValidation",
        props: {
            msg: String,
        },

        components: {
            "sc-loader": Loader,
        },

        data: () => ({
            email: "",
            licKey: "",
            deviceID: "",
            model: "",
            isLoading: false,
            key: "",
            error: null,
            licenseExpired: false,
            licenseMsg: "",
        registering: false,
        wasRegistered: false,
        registerMsg: "",
            }),
        created() {
            this.key = "files";
            this.getObject();
            this.validateLicense();
        },

        computed: {
            supportURL() {
                //return the SUPPORT URL
                return process.env.VUE_APP_SUPPORT_URL;
            },
        },

        methods: {
            getObject() {
                this.error = null;
                try {
                    return SimpleIDB.SimpleIDB.get(this.key);
                } catch (e) {
                    this.error = e.message;
                }
            },

            validateLicense() {
                // if (localStorage.getItem("licensed") != "true" && store.getters.fromFulcrum) {

                // }
            if (this.checkIfRegisteringFromFulcrum()) {
                return;
            }

            if (localStorage.getItem("licensed") == "true") {
                this.hasNetwork(navigator.onLine);
                return;
                }
            
            },

            hasNetwork(check) {
                let lastConnectTime = new Date(
                    localStorage.getItem("lastConnectTime")
                ).getTime();
                //if (check && store.getters.fromFulcrum) {
                //    this.checkLicense();
                //    this.navigateToNextPage();
                //    return;
                //}
                // Hour24 is 24 hours after last connection time
                //let aWeekAgo = lastConnectTime + (24 * 60 * 60 * 1000) * 7;
                let hour24 = lastConnectTime + 24 * 60 * 60 * 1000;
                let currentTime = new Date().getTime();

                if (check) {
                    // if online and you have been connected in the last 24 hours
                    if (hour24 > currentTime) {
                        let lastCheck = localStorage.getItem("lastCheck");
                        if (
                            !lastCheck ||
                            new Date(lastCheck).getTime() + 24 * 60 * 60 * 1000 >= currentTime
                        ) {
                            localStorage.setItem("lastConnectTime", new Date());
                            store.dispatch("setIsAuthenticated", true);
                            this.navigateToNextPage();

                        } else {
                            this.checkLicense();
                        }
                    } else {
                        this.checkLicense();
                    }
                }
                // not online
                else {
                    // Not online and you have been connected in the last 24 hours
                    if (hour24 > currentTime) {
                        store.dispatch("setIsAuthenticated", true);

                        this.navigateToNextPage();

                    } else {
                        // if not from fulcrum or from fulcrum and have not been connected for a week
                        if (!store.getters.fromFulcrum) {
                            this.licenseMsg = "*License Expired";
                            store.dispatch("setIsAuthenticated", false);
                            localStorage.setItem("licensed", false);
                            this.licenseExpired = true;
                        }
                        else {
                            this.checkLicense();
                        }
                    }
                }
            },

            checkLicense(fromFulcrum=false) {
                
                this.isLoading = true;
                this.email = localStorage.getItem("emailID");
                this.licKey = localStorage.getItem("licenseKey");
                this.deviceID = localStorage.getItem("deviceID");
                this.model = localStorage.getItem("model");
                LicenseAPI.validateLicense(
                    this.licKey,
                    this.email,
                    this.deviceID,
                    this.model
                )
                    .then((response) => {
                        this.isLoading = false;
                        this.addDeviceMatrix(
                            response.data.resultStatus,
                            response.data.message,
                            response.data.customerName
                        );
                        if (response && response.data.resultStatus == "Success") {
                            localStorage.setItem("lastCheck", new Date());
                            localStorage.setItem("licensed", true);
                            localStorage.setItem("lastConnectTime", new Date());
                            store.dispatch("setIsAuthenticated", true);
                            if (fromFulcrum) {
                            this.registerMsg = "You have been registered successfully. Press here to continue";
                                return;
                            }
                            this.navigateToNextPage();

                            //this.$router.replace("/dashboard");
                        } else if (response && response.data.resultStatus == "Failure") {
                            store.dispatch("setIsAuthenticated", false);
                            localStorage.setItem("licensed", false);
                            this.licenseExpired = true;
                            this.licenseMsg = response.data.message;
                            if (fromFulcrum) this.registerMsg = "Registration not successfully. Press here to continue";
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        this.addDeviceMatrix("Failure", error);
                        console.log(error);
                        if (fromFulcrum) this.registerMsg = "Registration not successfully. Press here to continue";
                                   });
            },

            addDeviceMatrix(status, msg, name = null) {
                let payload = {
                    emailId: this.email,
                    licenseKey: this.licKey,
                    deviceId: this.deviceID,
                    customerName: name & (name !== "") ? name : null,
                    lastConnectDateTime: new Date(),
                    logStatus: status,
                    logMessage: msg,
                    logOrigin: this.model,
                    logComments: msg,
                    loggedDateTme: new Date(),
                };

                LicenseAPI.addDeviceMatrix(payload)
                    .then(() => { })
                    .catch((error) => {
                        console.log(error);
                    });
            },

            checkIfRegisteringFromFulcrum() 
            {
                let fromFulcy = store.getters.fromFulcrum;

                if (fromFulcy) 
                {
                this.deviceID = localStorage.getItem("deviceID");
                this.model = localStorage.getItem("model");
                let startParams = JSON.parse(localStorage.getItem("startParameters"));
                    let struct_id = startParams.struct_id;
                if (!this.deviceID || this.deviceID == "" || struct_id.startsWith("_register")) 
                {
                    let email1 = this.email;
                    if (struct_id.startsWith("_register")) 
                        email1 = struct_id.split('-')[1];
                   Device.getInfo().then((info) => 
                    {
                       let deviceinfo = info;
                        // console.log("This.Email: ", this.email);
                        // console.log("Email1: ", email1);
                        let infoText = `${deviceinfo.manufacturer}${deviceinfo.model}${deviceinfo.operatingSystem}${deviceinfo.osVersion}${deviceinfo.platform}${email1}`;
                        this.deviceID = uuidv5(infoText, uuidv5.URL);
                        localStorage.setItem("deviceID", this.deviceID);
                        },
                        (e) => console.log("there was an error", e)
                    );
                }
                if (struct_id.startsWith("_register")) 
                {
                    this.registering = true;
                    let email = struct_id.split('-')[1];
                    localStorage.setItem("emailID", email);

                   
                        LicenseAPI.getFulcrumLicense("register", email.trim(), this.deviceID, this.model)
                            .then((response) => {
                                // debugger;
                                if (response && response.data.resultStatus == "Success" && (response.data.messageCode == 107 || response.data.messageCode == 108)) {
                                    this.submittedLicKey = response.data.message;
                                    localStorage.setItem("licenseKey", response.data.message);
                                    localStorage.setItem("licensed", "true");
                                    this.wasRegistered = true;
                                    this.registering = false;
                                    this.checkLicense(true);
                                // if (this.licenseExpired)
                                //         this.registerMsg = "Registration not successfully. Press here to continue";
                                //     else
                                //         this.registerMsg = "You have been registered successfully. Press here to continue";
                                    return this.registering;
                                } else {
                                    this.wasRegistered = true;
                                    this.registering = false;
                                    this.registerMsg = "Unable to Register, try again later. Press here to continue";
                                    return true;
                                }
                        }).catch(() => {
                            
                        this.wasRegistered = true;
                        this.registering = false;
                        this.registerMsg = "Unable to Register, try again later. Press here to continue";
                        return true;} );
                    
                                          
                }
             return this.registering;
             }
        },

            onSupportClick() {
                let showSettings = false;
                this.$router.push({
                    name: "Support",
                    params: { showSettings },
                });
            },
        onRegisteredClick() {
            finish({ simple_result: JSON.stringify("") });
                },
            navigateToNextPage() {
                if (store.getters.fromFulcrum) { this.$router.replace("/evaluation"); }
                else { this.$router.replace("/dashboard"); }
            },
            onLicenseProductClick() {
                this.$router.push("/licence-registration");
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .osmoseUrl {
        text-align: center;
        color: #fff;
        font-size: 12px;
        display: block;
        margin-top: 10px;
    }

    .mainPage {
        background: #003468;
        height: 100vh;
    }

    .headerLogo {
        img {
            width: 230px;
        }
    }

    .mainPageContent {
        margin: auto;
    }

    .listInline {
        list-style: none;
        padding-left: 0;
        font-size: 14px;
        margin: 1rem 0px;

        .listItem {
            display: inline-block;
            margin-right: 1rem;

            a {
                text-decoration: none;
            }
        }

        .listItem:last-child {
            margin-right: 0rem;
        }
    }

    .bottomFixed {
        position: fixed;
        bottom: 0px;
        width: 100%;
        text-align: center;

        .listInline a {
            color: #fff;
        }
    }

    .contentPanel {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%);
        width: 350px;
        text-align: center;
    }

    .title1 {
        margin: 2rem 0px;
        text-align: center;
        color: #fff;
        font-weight: 600;
        font-size: 32px;
    }

    .btnwht {
        background: #fff;
        border-radius: 8px;
        padding: 20px 16px;
        color: #003468;
        font-size: 16px;
        border: 0px;
        cursor: pointer;
        font-weight: 600;
        width: 100%;
        margin: 15% 0px;
    }

    .textRed {
        color: #ff0000;
        font-size: 16px;
        font-weight: 600;
        margin: 1rem 0px;
    }

    //Tabs Only
    @media (min-width: 768px) {
    }

    //Desktop only
    @media (min-width: 992px) {
    }

    //Small mobile
    @media (max-width: 374.5px) {
        .contentPanel {
            width: 90%;
        }
    }
</style>
